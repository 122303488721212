import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
// import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";

//import ServiceSubscription from "../siteAdmin/customerfeatures/ServiceSubscription";
import ServiceSubscription from "./dashboard/ServiceSubscriptionOnarts";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";

import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";


import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";

import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";

import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";

import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";

import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class OnlineArticlesBd extends Component {
  render() {
    return (
        <HashRouter> 
         {/* <Route path="/makepaymentbtonarts" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbponarts" component={MakePaymentBp} /> 
          <Route path="/makepaymentolonarts" component={MakePaymentOl} />  */}

          <Route path="/helponarts" component={Help} />      
          <Route path="/visitsonarts" component={Visits} />              
          <Route path="/loginscreenonarts" component={Loginscreen} />   
          <Route path="/contactonarts" component={Contact} />     
          <Route path="/registeruseronarts" component={RegisterUser} />
          <Route path="/performancesonarts" component={Performances} /> 
          <Route path="/generateperformanceonarts" component={GeneratePerformance} /> 
          <Route path="/changepasswordonarts" component={ChangePassword} /> 
          <Route path="/loginonarts" component={Login} /> 
          <Route path="/logoutonarts" component={Logout} /> 
          <Route path="/servicesubonarts" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedonarts" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsonarts" component={UploadAssets} />
          <Route path="/uploadpicturesonarts" component={UploadPictures} />
          <Route path="/uploadvideosonarts" component={UploadVideo} />          
          <Route path="/detailsdevonarts" component={AssetDetailsPageDev} />
          <Route path="/detailsonarts" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationonarts" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedonarts" component={ThankyouPasswordChanged} />
          <Route path="/buyticketonarts" component={BuyTicket} />
          <Route path="/collectaninviteasviponarts" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinviteonarts" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventonarts" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinviteonarts" component={CreateOrdinaryInvite} />
          <Route path="/createvipinviteonarts" component={CreateVIPInvite} />
          <Route path="/createeventticketonarts" component={CreateEventTicket} />
          <Route path="/displayinviteonarts" component={DisplayInvite} />
          <Route path="/displayperformanceonarts" component={DisplayPerformance} />
          <Route path="/displayeventticketonarts" component={DisplayEventTicket} />
          <Route path="/manageeventonarts" component={ManageEvent} />
          <Route path="/displaymydashboardonarts" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsonarts" component={SeeParticipants} />
    
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(OnlineArticlesBd);
