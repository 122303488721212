// import React, {Component,  useState, useEffect } from 'react';
// import { withTranslation } from "react-i18next";
// import { NavLink, Link, HashRouter } from "react-router-dom";
// import { Row, Col } from 'react-bootstrap';
// import LocalStorageService from './services/localStorageService';
// import DashboardCarousel from './DashboardCarousel';
// class HomeDashboard extends Component {
//   render() {
//     const { t } = this.props;
//     LocalStorageService("remove","servicecategory")
//     LocalStorageService("set","servicecategory", "home")
//     return (
//       <div>

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../services/urlService";
import GetEnvironment  from"../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../services/localStorageService";
import LoadMoreAssets from "./LoadMoreAssetsRoot";
import NavBarsocialMedias from "../responsive/Navbar";

function HomeDashboardMix() { 
  const [env,  setEnv] = useState('')
   return (  
    <div className="container signupforevents-party-lp-content"> 
   
       <LoadMoreAssets/> 
       <p></p>   
       
        </div>
        )
}
export default HomeDashboardMix




//         <div>  
//       <DashboardCarousel/>
//        </div>
//        <HashRouter>
//         <div>
//      <Row> 
//         {/* <Col md={{ span: 9, offset: 2}}> */}
        
//            <Col id='col-dashboard-1'>           
//            <ul id="menu">
//             <li>
//               <NavLink to="/hairandbeauty"><span className ="header-akwaba-tiles-navlinks1">▶️Hair</span></NavLink>            
//               </li>             
//             </ul>           
//             </Col> 
           
//             <Col id='col-dashboard-2'>
//             <ul id="menu">
//             <li>
//               <NavLink to="/merchandise"><span className ="header-akwaba-tiles-navlinks2">▶️Marketplace</span></NavLink>            
//               </li>             
//             </ul>
//             </Col>
           
//             <Col id='col-dashboard-3'> 
//             <ul id="menu">
//             <li>
//               <NavLink to="/home"><span className ="header-akwaba-tiles-navlinks3">▶️Assembly</span></NavLink>            
//             </li>             
//             </ul>
//           </Col>
         
//           <Col id='col-dashboard-4'>
//           <ul id="menu">
//           <li>
//               <NavLink to="/catering"><span className ="header-akwaba-tiles-navlinks4">▶️Catering</span></NavLink>            
//            </li>         
//            </ul>
//           </Col> 
          
//        </Row>
//        </div>   
//      </HashRouter> 

     
//      </div> 
      
//     );
//   }
// }

// export default withTranslation() (HomeDashboard);
