

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from"../../services/urlService";
import GetEnvironment  from"../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
import LoadMoreAssets from "./pages/landingPages/LoadMoreAssetsRoot";
import SetAppName from "./socialMediasServices/setAppName";
import NavBarsocialMedias from "./responsive/Navbar";
function SocialMedias() { 
  const [env,  setEnv] = useState('')
   return (  
    <div className="container signupforevents-party-lp-content"> 
    
       <NavBarsocialMedias/>
       <LoadMoreAssets/> 
       <p></p>   
       
        </div>
        )
}
export default SocialMedias


