import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
import Contact from "../../footer/askus";
import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";
import ServiceSubscription from "./dashboard/ServiceSubscriptionMiscellaneous";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";
import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";
import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";
import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class MiscellaneousBody extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpmisc" component={Help} />      
          <Route path="/visitsmisc" component={Visits} />              
          <Route path="/loginscreenmisc" component={Loginscreen} />   
          <Route path="/contactmisc" component={Contact} />     
          <Route path="/registerusermisc" component={RegisterUser} />
          <Route path="/performancesmisc" component={Performances} /> 
          <Route path="/generateperformancemisc" component={GeneratePerformance} /> 
          <Route path="/changepasswordmisc" component={ChangePassword} /> 
          <Route path="/loginmisc" component={Login} /> 
          <Route path="/logoutmisc" component={Logout} /> 
          <Route path="/servicesubmisc" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedmisc" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsmisc" component={UploadAssets} />
          <Route path="/uploadpicturesmisc" component={UploadPictures} />
          <Route path="/uploadvideosmisc" component={UploadVideo} />          
          <Route path="/detailsdevmisc" component={AssetDetailsPageDev} />
          <Route path="/detailsmisc" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationmisc" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedmisc" component={ThankyouPasswordChanged} />
          <Route path="/buyticketmisc" component={BuyTicket} />
          <Route path="/collectaninviteasvipmisc" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitemisc" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventmisc" component={ConfirmParticipationToEvent} />         
          <Route path="/manageeventmisc" component={ManageEvent} />
          <Route path="/displaymydashboardmisc" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsmisc" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(MiscellaneousBody);
