import {React, useState, useEffect} from 'react';
import styled from 'styled-components';
import {NavLink, HashRouter } from "react-router-dom";
import { useTranslation  } from "react-i18next";
import { Row, Col } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import LocalStorageService from '../services/localStorageService';
import Button from 'react-bootstrap/Button';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: auto;
    width: auto;
    padding-top: 3.5rem;
    z-index:5;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

function RightNav ({open } )  {
    const { t } = useTranslation();

    const[longStatus, setLoginStatus] = useState('');
    const[liveToken, setLiveToken] = useState('');
    const[servicecategory, setServicecategory] = useState('');

    var loginToken = LocalStorageService("get","token");
    var userType = LocalStorageService("get","userType");
    var userAdmin = userType =='A';
    var userCustomer = userType =='C';

    useEffect(()=>{
      updateLoginStatus();
    },[])

 

function updateLoginStatus () {
  setLoginStatus(LocalStorageService("get","token"));
}

      return (
  
    <div>      
      <Row >      
       <Col md={{ span: 12, offset: 0 }} >      
        <Ul open={open} id="menu"> 
        <HashRouter>
          <p></p>         
                   <li>
                      <Dropdown>
                          <Dropdown.Toggle variant="" id="" Style ="background-color:teal">
                         <Button variant = "" Style ="background-color:teal">{t("navbar.services")}</Button> 
                          </Dropdown.Toggle>

                           <Dropdown.Menu>
                           { userAdmin && loginToken !== null &&( <Dropdown.Item href="#"><NavLink to="/servicesubscription">
                            <span className="header-akwaba-rightnavbar-navlinks">
                           My DASHBOARD
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>)}
                          
                           <Dropdown.Item href="#"><NavLink to="/newspapers">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            News Papers
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>

                            <Dropdown.Item href="#"><NavLink to="/socialmedias">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Social medias
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>

                            <Dropdown.Item href="#"><NavLink to="/onlinearticles">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Online articles
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink>                            
                            </Dropdown.Item>

                            <Dropdown.Item href="#"><NavLink to="/tvbroadcasts">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Tv broadcasts
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>

                            <Dropdown.Item href="#"><NavLink to="/radiobroadcasts">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Radio broadcasts
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink>                            
                            </Dropdown.Item>

                           <Dropdown.Item href="#"><NavLink to="/miscellaneous">
                            <span className="header-akwaba-rightnavbar-navlinks">
                            Miscellaneous
                            {/* {t("pages.marveltechgroup.group.text.solutions")}*/}
                            </span></NavLink> 
                            </Dropdown.Item>  
                          </Dropdown.Menu> 
                      </Dropdown>
                    </li> 
                    <li>                   
                    <Dropdown>
                          <Dropdown.Toggle variant="" Style ="background-color:teal">
                          <Button variant = "" Style ="background-color:teal"> {t("navbar.contact")}</Button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                           <Dropdown.Item href="#"> <NavLink to="/contact">
                            <span className ="header-akwaba-rightnavbar-navlinks">
                              {t("navbar.contactus")}</span></NavLink> 
                         </Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown> 
                    </li>
                                                                 
        </HashRouter>       
        </Ul>
      </Col>
      </Row>  
  </div>
  )
}
export default (RightNav);


