

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetUrl from "../../services/urlService";
import GetEnvironment  from "../../services/getEnvironment";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
import LoadMoreAssetsRoot from "./pages/landingPages/LoadMoreAssetsRoot";
import NavBarEntertainment from "./responsive/Navbar";

function OnlineArticlesLP() { 
  const [env,  setEnv] = useState('')
  return (  
    <div className="container signupforevents-party-lp-content"> 
      
       <NavBarEntertainment/>
       <LoadMoreAssetsRoot/> 
       <p></p>   
       
        </div>
        )
}
export default OnlineArticlesLP


