import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";
// import Services from "../clientappHairtechnicians/pages/Services";
import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";

//import ServiceSubscription from "../siteAdmin/customerfeatures/ServiceSubscription";
import ServiceSubscription from "./dashboard/ServiceSubscriptionSocmed";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";

import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";
import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";
// import ApproximateCostPerParticipant from "../../payment/ApproximateCostPerParticipant";
// // import ApproximateCostOfEvent from "../../payment/ApproximateCostPerEvent";

import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";

import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";

import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplaymyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";


// import MakePayment from "../../payments/MakePaymentRoot";

import Help from "./help";
import Visits from "./UserVisits";

import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class SocialMediasBody extends Component {
  render() {
    return (
        <HashRouter> 
         {/* <Route path="/makepaymentbtsocmed" component={MakePaymentBankTransfert} /> 
          <Route path="/makepaymentbpsocmed" component={MakePaymentBp} /> 
          <Route path="/makepaymentolsocmed" component={MakePaymentOl} />  */}

          <Route path="/helpsocmed" component={Help} />      
          <Route path="/visitssocmed" component={Visits} />              
          <Route path="/loginscreensocmed" component={Loginscreen} />   
          <Route path="/contactsocmed" component={Contact} />     
          <Route path="/registerusersocmed" component={RegisterUser} />
          <Route path="/performancessocmed" component={Performances} /> 
          <Route path="/generateperformancesocmed" component={GeneratePerformance} /> 
          <Route path="/changepasswordsocmed" component={ChangePassword} /> 
          <Route path="/loginsocmed" component={Login} /> 
          <Route path="/logoutsocmed" component={Logout} /> 
          <Route path="/servicessubsocmed" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedsocmed" component={ThankYouUploaded} /> 
          <Route path="/uploadassetssocmed" component={UploadAssets} />
          <Route path="/uploadpicturessocmed" component={UploadPictures} />
          <Route path="/uploadvideossocmed" component={UploadVideo} />          
          <Route path="/detailsdevsocmed" component={AssetDetailsPageDev} />
          <Route path="/detailssocmed" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationsocmed" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedsocmed" component={ThankyouPasswordChanged} />
          <Route path="/buyticketsocmed" component={BuyTicket} />
          <Route path="/collectaninviteasvipsocmed" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinvitesocmed" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventsocmed" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinvitesocmed" component={CreateOrdinaryInvite} />
          <Route path="/createvipinvitesocmed" component={CreateVIPInvite} />
          <Route path="/createeventticketsocmed" component={CreateEventTicket} />
          <Route path="/displayinvitesocmed" component={DisplayInvite} />
          <Route path="/displayperformancesocmed" component={DisplayPerformance} />
          <Route path="/displayeventticketsocmed" component={DisplayEventTicket} />
          <Route path="/manageeventsocmed" component={ManageEvent} />
          <Route path="/displaymydashboardsocmed" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantssocmed" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(SocialMediasBody);
