//https://www.itsolutionstuff.com/post/password-and-confirm-password-validation-in-reactexample.html

import { useTranslation } from 'react-i18next';
import { React,useEffect,useState} from "react";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import UploadPictures from './UploadPictures';
import UploadVideo from './UploadVideo';
import GetUrl from "../../../customEventsServices/urlServiceCustomEvents";
import LocalStorageService from '../../../../../services/localStorageService';
import ReactPlayer from 'react-player';
import setAppName from "../../../customEventsServices/setAppName";
import config from '../../../../../config.json';
import { Row, Col } from "react-bootstrap";
import Obscene from "../../../../../obscene.json";

const CreateEventBase = () => {
  setAppName();
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [organiserFullName, setOrganiserFullName] = useState('');
  const [category, setCategory] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [contactEmail, setContactEmail] = useState(localStorage.getItem('userEmail'));
  const [contactPhone, setContactPhone] = useState('');
  const [yourLocation, setYourLocation] = useState('');
  const [description, setDescription] = useState('');
  const [size, setSize] = useState('');
  const [state, setState] = useState('');
  const [colour, setColour] = useState('');
  const [openingModel, setOpeningModel] = useState('');  
  const [image, setImage] = useState('');
  const [serviceModel, setServiceModel] = useState('');
  const [deliveryModel, setDeliveryModel] = useState('');  
  const [firstoptionalimage, setOptionalImage1] = useState('');
  const [secondoptionalimage, setOptionalImage2] = useState('');
  const [datepickeravailfrom, setAvailfrom] = useState(new Date());
  const [datepickeravailto, setAvailto] = useState(new Date());
  const [timestart, setTimestart] = useState('');
  const [timeend, setTimeend] = useState('');
  const [note, setNote] = useState('');
  const [dateOfEvents, setDateOfEvents] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const [seesubmitbutton, setSeesubmitbutton] = useState(true);  
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [fnameerrormsg, setFirstNameerrormsg] = useState('');
  const [lnameerrormsg, setLastNameerrormsg] = useState('');
  const [titleerrormsg, setTitleerrormsg] = useState(''); 
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [phonenumbererrormsg, setPhoneNumbererrormsg] = useState('');
  const [generalerrormsg, setGeneralerrormsg] = useState('');
  const [reasonerrormsg, setReasonerrormsg] = useState('');
  const [seenote, setSeenote] = useState(true);
  const [isValid, setIsvalid] = useState(false);
  const [env, setEnvironment] = useState('');
  const [demoVideoPath, setDemoVideoPath] = useState('');  
  const [occurence, setOccurence] = useState('');
  const [geographicalCoverage, setGeographicalCoverage] = useState('');
  const [venue, setVenue] = useState('');
  const [code, setCode] = useState('');
  const [vipcode, setVipcode] = useState('');
  const [participationStatus, setParticipationStatus] = useState('');
  const [address, setAddress] = useState('');
  const [eventName, setEventName] = useState('');  
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation();

  function getApiPath () { 
    return GetUrl("insertAsset")
  };

  function loginToken (){  
    return LocalStorageService("get", "token")
  }; 

  const videosrcdev = `registerevent.mp4`;
  const videodev = require(`../../../../../endpoints/videos/${videosrcdev}`).default;
  const videolive = `/videos/${videosrcdev}`??'';

  function LoadDemoVideo(){
    if(config.ENVIRONMENT.DEV ==true)
    {
      setDemoVideoPath(videodev)
    }
    else{
      setDemoVideoPath(videolive)
    }
  }

  useEffect(() => {
    LoadDemoVideo();
  }, [])


  useEffect(() => {
    setEnvironment(LocalStorageService("get", "env"));
  }, [])

//  const email = loginEmail();
 const token = loginToken();

  const isLoggedin = token!=null;

  function execute (event)  {
    LocalStorageService("remove", "insertedId"); 
    setEnvironment(LocalStorageService("get", "env"));
    event.preventDefault();
    if (validate()) {
      console.info("Valid Form");   
      handleSubmit(event);    
    } else {
        setClickedButtonButNotPosted(true);
        setClickedNotPostedMessage(<p>{t("pages.contact.text.msginvalidform")} </p>);  
        console.log("Data was not sent");     
      console.error("Invalid Form");
      return;
    }
  };

  function validAgaisntObscenity(text) {
    if (typeof text !== "undefined") {
     
      var obscene = Obscene.Forbidden.obscene; 
      var obscenefrench = Obscene.Forbidden.obsceneFrench;
      var obsceneAny = Obscene.Forbidden.obsceneAny;

      let myItem =[];
      obscene.forEach((item)=>{myItem.push(item)});
      obscenefrench.forEach((item)=>{myItem.push(item)});
      obsceneAny.forEach((item)=>{myItem.push(item)});

        var arrReason = text.toLowerCase().split(" ");
      // Iterate through each element in the
      // first array and if some of them 
      // include the elements in the second
      // array then return true
        return !arrReason.some(item => myItem.includes(item))      
    }

  }

  const handleSubmit = event => {
   
    var typeOfEvent="cuevt";
    event.preventDefault(); // 👈️ prevent page refresh
    const body ={fname, lname, title, openingModel,
     note,category,country,city, contactEmail,contactPhone,
     yourLocation,description,size,datepickeravailfrom,serviceModel,  
     datepickeravailto ,occurence, organiserFullName, venue, 
     deliveryModel, code, vipcode, address, eventName, participationStatus, typeOfEvent};
   
   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
              
      const API_PATH = getApiPath();     
      axios({
        method: "post",     
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) { 
           console.log({result})
            setMessageSent(true); 
            LocalStorageService("set", "email", contactEmail);
            LocalStorageService("set", "insertedId", result.data);        
            setServiceModel('');
            setOccurence('');
            setServiceModel('');         
            setFirstName('');
            setLastName('');
            setTitle('');
            setCategory('');
            setCountry('');
            setCity('');
            setContactEmail('');
            setContactPhone('');
            setYourLocation('');
            setOrganiserFullName('');
            setDescription('');
            setGeographicalCoverage('');
            setSize('');
            setDeliveryModel('');
            setColour('');
            setVenue('');
            setCode('');
            setVipcode('');
            setParticipationStatus('');
            setAddress('');
            setEventName('');
            setOrganiserFullName('');
            setOpeningModel('');
            setImage('');
            setOptionalImage1('');
            setOptionalImage2('');
            setAvailfrom('');
            setAvailto('');
            setState('');
            setNote('');
            setSeenote(false);
            setDateOfEvents('');          
            setFirstNameerrormsg('');
            setLastNameerrormsg('');
            setTitleerrormsg('');
            setGeneralerrormsg('');
            setEmailerrormsg('');
            setPhoneNumbererrormsg('');
            setReasonerrormsg('');           
            setSeesubmitbutton(false);
            setClickedButtonButNotPosted(false);
            setClickedNotPostedMessage('');
            setServiceModel(); 
          }
        })
        .catch(function (error) {    
          console.log(error);
        });     
    
  }

 const  validate = ()=> {
    let isValid = true; 
    if (!title || !validAgaisntObscenity(title)) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    
    
    if (!fname || !validAgaisntObscenity(fname)) {
      isValid = false;
      setFirstNameerrormsg(<p>{t("pages.contact.text.firstnameinvalidmsg")}</p>);
    }

    if (!lname || !validAgaisntObscenity(lname)) {
      isValid = false;
      setLastNameerrormsg(<p>{t("pages.contact.text.lastnameinvalidmsg")}</p>);
    }

    if (!contactPhone || !validAgaisntObscenity(contactPhone)) {
      isValid = false;
      setPhoneNumbererrormsg(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }

     if (!code || !validAgaisntObscenity(code)) {
      isValid = false;
      setGeneralerrormsg(<p>code is invalid</p>);
     }

     if (!vipcode || !validAgaisntObscenity(vipcode)) {
      isValid = false;
      setGeneralerrormsg(<p>VIP code is invalid</p>);
     }

     if (!address|| !validAgaisntObscenity(address)) {
      isValid = false;
      setGeneralerrormsg(<p>address is invalid</p>);
     }

     if (!eventName|| !validAgaisntObscenity(eventName)) {
      isValid = false;
      setGeneralerrormsg(<p>Event name is invalid</p>);
     }

     if (!organiserFullName|| !validAgaisntObscenity(organiserFullName)) {
      isValid = false;
      setGeneralerrormsg(<p>Organiser name is invalid</p>);
     }

    if (!contactEmail || !validAgaisntObscenity(contactEmail)) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof contactEmail !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contactEmail)) {
        isValid = false;
        setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }

    if ((!note) || !validAgaisntObscenity(note)){
      isValid = false;
      setReasonerrormsg(<p>{t("pages.contact.text.subjectinvalidmsg")}</p>);
    }  

 
    if (!datepickeravailfrom && !datepickeravailto) {
      isValid = false;
      setTitleerrormsg(<p>{t("pages.contact.text.titleinvalidmsg")}</p>);
    }
    else{
      if (datepickeravailto < datepickeravailfrom)
      {
        isValid = false;
      }
    }

    setIsvalid(isValid);

    return isValid;
  //}
  };
  
  return (
   <div  className="content-akwaba">
    {isLoggedin && (  <form action="#">
        <div className="case-form" Style="">
        <p>Fill this form to populate your property details</p>   
       <p>Watch a demo</p>
          </div>   
        
       <Accordion defaultActiveKey="0">  
        <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    <div className="case-form" Style=""><p>Watch a demo</p></div>  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
        <div className="col-md-6 offset-3">
        <div className="body-pannel">   
        <video width="300px" height="400px" controls>
           <source src={demoVideoPath} type="video/mp4" />
      </video>
      </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    Your personal info   
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body className="content-accordion" >
        <div className="col-md-9 offset-4">
      <div className="form-group">
        <label>{t("pages.contact.text.title")} </label>        
        <select className="form-group  option-group" name= "title" id="title" value={title}  onChange={event => setTitle(event.target.value)} >
                <option value="">{t("pages.contact.default")}</option>
                <option value={t("pages.contact.sir")}>{t("pages.contact.sir")}</option>
                <option value={t("pages.contact.madam")}>{t("pages.contact.madam")}</option>
                <option value={t("pages.contact.unspecified")}>{t("pages.contact.unspecified")}</option>  
                <option value={t("pages.contact.other")}>{t("pages.contact.other")}</option>
                <option value="Royal">Royal</option>                                 
              </select>
          <div className="text-danger">{titleerrormsg}</div>
        </div>

      <div className="form-group">
        <label>Your first name</label>
        <input
         readonly
          id="firstname"
          name="firstname"
          type="text"
          value={fname}
          onChange={event => setFirstName(event.target.value)}          
          placeholder="your first name"
          className= "blured-input"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
         <label>Your last name</label>
        <input
          readonly 
          id="lastname"
          name="lastname"
          type="text"
          value={lname}
          onChange={event => setLastName(event.target.value)}
          placeholder="Estate and Building"
          className= "blured-input"
        />
          <div className="text-danger">{lnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Your email</label>
        <input
        readonly
          id="contactEmail"
          name="contactEmail"
          type="text"
          value={contactEmail}
          onChange={event => setContactEmail(event.target.value)}
          placeholder="something@domain.com"
          className= "blured-input"
        />
          <div className="text-danger">{emailerrormsg}</div>
      </div>

      <div className="form-group">
              <label>Your phone</label>
         <input
         readonly
          id="contactPhone"
          name="contactPhone"
          type="text"
          value={contactPhone}
          onChange={event => setContactPhone(event.target.value)} 
          placeholder="123456789"
          className= "blured-input"
        />
          <div className="text-danger">{phonenumbererrormsg}</div>
        </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
        Your Event info    
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion">
        
        <div className ="col-md-9 offset-4">
      <div className="form-group">
        <label>{t("Category")} </label>
          <select className ="form-group  option-group" name= "category" id="category" value={category}  onChange={event => setCategory(event.target.value)} >
            <option value="">Select category</option>
            <option value="Ceremonies">Ceremonies</option>
            <option value="Conferences">Conferences</option>         
            <option value="Creative">Creative</option> 
            <option value="CustomEvents">Custom events</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Fairs">Fairs</option>
            <option value="Festivals">Festivals</option>         
            <option value="tvBroadcasts">tvBroadcasts</option> 
            <option value="Music">Music</option>
            <option value="socialMedias">socialMedias</option>    
            <option value="Spectacles">Spectacles</option>
            <option value="newsPapers">newsPapers</option>         
            <option value="Tourism">Tourism</option> 
            <option value="radioBroadcasts">radioBroadcasts</option>
            <option value="Other">Other Categories</option>                          
          </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>If periodically When the event take place</label>
        <input
          id="occurence"
          name="occurence"
          type="text"
          value={occurence}
          onChange={event => setOccurence(event.target.value)}          
          placeholder="Every day. Otherwise specify the days"
        />
        
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
          <Row>
            {/* <Col Style = {{width:"auto", minWidth:"100px", color: "teal"}}> */}
            <Col>
        <label>Dates event starts</label>
          <input Style = {{width:"auto", minWidth:"100px", color: "teal"}}
          id="datepickeravailfrom"
          name="datepickeravailfrom"
          type="datetime-local"
          step="1"         
          value={datepickeravailfrom}
          onChange={event => setAvailfrom(event.target.value)}          
          placeholder="Date start"
        />
          <div className="text-danger">{fnameerrormsg}</div>
          </Col>
          </Row>
        </div>
        <div>    
      </div>

        <div className="form-group">
        <Row>
        <Col>         
        <label>Dates event Ends</label>
        <input Style = {{width:"auto", minWidth:"100px", color: "teal"}}
          id="datepickeravailto"
          name="datepickeravailto"
          type="datetime-local"
          step="1"
          startDate={datepickeravailfrom}
          minDate={datepickeravailfrom}
          value={datepickeravailto}
          onChange={event => setAvailto(event.target.value)}          
          placeholder="Date end"
        />
          <div className="text-danger">{fnameerrormsg}</div>
          </Col>
          </Row>
        </div>
      
        <div className="form-group">
        <label>Name of your event (e.g Joe Birthday Party) will help people search</label>
        <input
          id="eventName"
          name="eventName"
          type="text"
          value={eventName}
          onChange={event =>setEventName(event.target.value)}          
          placeholder="event Name"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Short description of how you provide your event. This will be displayed on your profile.</label>
        <input
          id="description"
          name="description"
          type="text"
          value={description}
          onChange={event =>setDescription(event.target.value)}          
          placeholder="Description"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>


        <div className="form-group">
        <label>Contract type </label>      
          <select className="form-group  option-group" name= "serviceModel" id="serviceModel" value={serviceModel}  onChange={event => setServiceModel(event.target.value)} >
            <option value="">Select a contract</option>
            <option value="SingleEvent">One off event</option>            
            <option value="MultiPerformanceEvent">Multi performances</option>  
            <option value="Other">Other</option>                             
          </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        </div> 

          </Card.Body>
              </Accordion.Collapse>
            </Card>
  
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
          Your event Venue info
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body className="content-accordion">
              <div className ="col-md-9 offset-4">
              <div className="form-group">
        <label>Where do you intend to hold the event (Delivery model) </label>
        <select className="form-group  option-group" name= "deliveryModel" id="deliveryModel" value={deliveryModel}  onChange={event => setDeliveryModel(event.target.value)} >
                <option value="">Select delivery model</option>
                <option value="AtHome">At home</option>
                <option value="InVenue">In a specified Venue</option>
                <option value="NoVenue">No venue specified</option>
                <option value="Other">Other to be specified later</option>      
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

            <div className="form-group">
            <label>if you hold event in venue please specify opening hours</label>      
             <select className="form-group  option-group" name= "openingModel" id="openingModel" value={openingModel}  onChange={event => setOpeningModel(event.target.value)} >
                <option value="">Open hours</option>
                <option value="7j24">24/7 </option>
                <option value="Fewdays">A few days a week</option>
                <option value="Weekends">Weekends</option>
                <option value="Weekdays">Week days</option>
                <option value="Weekdaysplusoneweekendday">Week days plus one wekend day</option>
                <option value="NotApplicable">Not applicable </option>                        
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
      </div>

      <div className="form-group">
        <label>Your venue</label>
        <input
          id="venue"
          name="venue"
          type="text"
          value={venue}
          onChange={event => setVenue(event.target.value)}          
          placeholder={t("Venue")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>If Multiple events, first event Venue country</label>
        <input
          id="country"
          name="country"
          type="text"
          value={country}
          onChange={event => setCountry(event.target.value)}          
          placeholder={t("country")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>first eventCity </label>
        <input
          id="city"
          name="city"
          type="text"
          value={city}
          onChange={event => setCity(event.target.value)}          
          placeholder={t("city")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>First event venue address </label>
        <input
          id="address"
          name="address"
          type="text"
          value={address}
          onChange={event => setAddress(event.target.value)}          
          placeholder={t("Address")}
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

      

        <div className="form-group">
        <label>If it is not yourself, organiser full name </label>
        <input
          id="organiserFullName"
          name="organiserFullName"
          type="text"
          value={organiserFullName}
          onChange={event => setOrganiserFullName(event.target.value)}          
          placeholder="Organiser full name"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>
               
        <div className="form-group">
        <label>Number of people can you take </label>
        <select className="form-group  option-group" name= "size" id="size" value={size}  onChange={event => setSize(event.target.value)} >
                <option value="">Select Event  size</option>
                <option value="canMicroEvent">Less than 50 people event </option>
                <option value="canMiniEvent">50 to 100 people event </option>
                <option value="canSmallEvent">100 to 200 people event </option>
                <option value="canMediumEvent">200 to 350 people event </option>
                <option value="canBigEvent">350 to 500 people event </option>
                <option value="canLargeEvent">500 and more people event </option> 
                <option value="cannotEvent">No event </option>      
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Code(allow people to easily find your event)</label>
        <input
          id="code"
          name="code"
          type="text"
          value={code}
          onChange={event => setCode(event.target.value)}          
          placeholder="Code"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>VIP code(Allow you to have special invitees)</label>
        <input
          id="vipcode"
          name="vipcode"
          type="text"
          value={vipcode}
          onChange={event => setVipcode(event.target.value)}          
          placeholder="VIP code"
        />
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

        <div className="form-group">
        <label>Is event free or payed or else? </label>
        <select className="form-group  option-group" name= "participationStatus" id="participationStatus" value={participationStatus}  onChange={event => setParticipationStatus(event.target.value)} >
                <option value="">Select delivery model</option>
                <option value="Free">Free</option>
                <option value="Contribution">Contribution</option>
                <option value="Donation">Donation</option>
                <option value="Pay">Pay</option>
                <option value="Other">Other to be specified later</option>      
              </select>
          <div className="text-danger">{fnameerrormsg}</div>
        </div>

       </div>  
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="4">
     A little more about the event 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
      <Card.Body className="content-accordion">
       <div className ="col-md-9 offset-4"> 
        {seenote && (<div className="form-group ">               
        <span><label>Describe in 100 words what your event is about. This will be displayed in your menu detail page.</label></span>
        <textarea
          id="note"
          name="note"
          type="text"
          rows={15}
          value={note}          
          onChange={event => setNote(event.target.value)}
          placeholder="Describe your dish"//{t("pages.contact.text.subjectph")}
        />
          <div className="text-danger">{reasonerrormsg}</div>      
       </div>)}

      {messageSent && (<span>Click 'picture Uploads' here below to continue</span>)} 
        
       {seesubmitbutton && (  <input
              className="btn btn-primary"
              type="submit"
              onClick={(e) =>  {execute(e)}}              
              value="Save and continue"
            />
          )}      
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card> 
  
  {messageSent && (
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="5"> 
    Picture upload
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="5">
      <Card.Body className="content-accordion">
      <div>      
      
        <div className="justify-content">    
        <div className='col-md-9 offset-4'>
       
        <UploadPictures/>
        </div>
        </div>
    
      </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>  
    )} 
</Accordion>      
{clickedButtonButNotPosted && (
        <div className="text-danger">
          {clickedNotPostedMessage} <br />                  
        </div>
         )}
      </form>)} 
    </div>
  );
};

export default CreateEventBase;


