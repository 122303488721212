import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useTranslation  } from "react-i18next";
import { createBrowserHistory } from 'history';
import GetUrl from "../../../onlineArticlesServices/urlServiceOnlineArticles";
import LocalStorageService from '../../../../../services/localStorageService';
import setAppName from "../../../onlineArticlesServices/setAppName";
//https://codesandbox.io/s/c53q2?file=/src/App.js:58-172

   const UploadVideo = (props) => { 
    setAppName();
  const [videoFile, setVideoFile] = useState();
  const [contactEmail, setContactEmail] = useState(localStorage.getItem('userEmail'));
  const [emailerrormsg, setEmailerrormsg] = useState('');
  const [productId, setProductId] = useState(LocalStorageService("get", "insertedId"));
  const [env, setEnvironment] = useState('');
  const [time, setTime] = useState(Date.now());
  function getApiPath (){ 
   return GetUrl("uploadVideo")
   };

  const { t } = useTranslation();

  useEffect(() => {
    validate();
      },[]);

      useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 300);
        return () => {
          clearInterval(interval);
        };
      }, []);
  
      const  validate = ()=> { 
      if(LocalStorageService("get", "userEmail")){
    
  
      const environment = LocalStorageService("get", "env");
  
      if(environment ==="dev")
      {
        setEnvironment("dev");
      }
    
      if(environment ==="test")
      {
        setEnvironment("test");
      }
    
      if(environment ==="live")
      {
        setEnvironment("");
      }
      }
  }



  const handleSubmit = event => {
  if (typeof contactEmail !== "undefined") {
    let isValid = true; 
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(contactEmail)) {
      isValid = false;
      setEmailerrormsg(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
    }
  }
  }

  return ( 
    <div  className="content-akwaba" >
    <Row>    
    <Col md={{ span: 9, offset: 2}}> 
    <div>
  <form action = {getApiPath()} method="post" enctype="multipart/form-data">
    
     <div className="form-group">
        <label>Login Email</label>
        <input
          readonly
          id="contactEmail"
          name="contactEmail"
          type="text"
          value={contactEmail}
          onChange={event => setContactEmail(event.target.value)}
          placeholder="re-enter email"
        />         
          </div>

          <div className="form-group">
        <label>Your product Id(please don't change this) </label>
        <input
          readonly
          id="productId"
          name="productId"
          type="text"
          value={productId}
          onChange={event => setProductId(event.target.value)}
          placeholder="Your product  id"
        />         
          </div>

          <div>
          <input
          type="file" 
          name="videoFile"
          id="videoFile"
          value={videoFile}
          onChange={event => setVideoFile(event.target.value)}
          placeholder="Upload video"
          /><br/><br/>
            </div>
          <div>
          <p>Upload your video. If you don't have a video, upload  a picture</p>
          <input type="submit" value= {env +" " + "Submit"}  name="submit" onClick={(e) =>  {handleSubmit(e)}}/>
          </div>      
   </form>
   </div>
    </Col>
    </Row>
    </div>    
  );
};


export default (UploadVideo);


