// //import PartyTicketPage from "../../categories/socialMedias/booking/TicketPage"

import axios from "axios";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router";
import React, { useState, useEffect } from 'react';
import { slice } from 'lodash';
import { NavLink, Link, HashRouter } from "react-router-dom";
import GetEnvironment  from "../../services/getEnvironment";
import GetBaseUrl from "../../services/getBaseUrl";
import { Row, Col } from "react-bootstrap";
import LocalStorageService from "../../services/localStorageService";
import TicketPage from "../socialMedias/booking/TicketPage";
import Button from 'react-bootstrap/Button';
import GlobalConfig from "../../config.json";

  const CollectATicket = () => {

  const [env,  setEnv] = useState('')
  const [category,  setCategory] = useState('')
  const [ticketid,  setTicketid] = useState('')
  const [errormsg,  setErrormsg] = useState('')
  const [isvalid,  setIsValid] = useState(false)

  useEffect(() => {
  setCategory(LocalStorageService("get", "servicecategory"))
  }, [])
 
  function validate(event){
  //let isValid = false; 
  event.preventDefault(); // 👈️ prevent page refresh
  if (typeof ticketid !== "undefined" && ticketid.length >10) {
    setIsValid(true);  
  }
  else{
    setErrormsg(<p>Invalid ticket number</p>);
  }
  return isvalid;
}

function handleSubmit(event){
  setErrormsg('');
  event.preventDefault(); // 👈️ prevent page refresh
 if (validate(event)) { 
  LocalStorageService("set", "TicketNumber", ticketid );
  LocalStorageService("set", "validStatus", isvalid );
 if(GetEnvironment()=="dev")
  window.location.href = GlobalConfig.ENVIRONMENT_BASE.DEV+'partyticketpage?'+ticketid
  if(GetEnvironment()=="live")
  window.location.href = GlobalConfig.ENVIRONMENT_BASE.LIVE+'partyticketpage?'+ticketid
  if(GetEnvironment()=="test")
  window.location.href = GlobalConfig.ENVIRONMENT_BASE.TEST+'partyticketpage?'+ticketid
    }
}

return (
  <div> 
   <form action ="#">
  <div className="container transaction-verification-container">
  <Col>
   <label><h3>Verify your ticket number</h3></label>
   </Col>
   <Col>
   <p></p>
   <input        
        id="ticketid"
        name="ticketid"
        type="text"
        value={ticketid}
        onChange={event => setTicketid(event.target.value)} 
        placeholder="Insert your invoice number"
      />
   </Col>  
   <Col md={{ span: 4, offset:4 }}>
     <Button variant="info" onClick={(e) =>  {handleSubmit(e)}} type ='submit'> Double click to proceed</Button>
   </Col>
   </div>       
    </form>
  <div></div>
   </div>
    )
}
export default (CollectATicket) 
