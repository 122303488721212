import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Route, HashRouter } from "react-router-dom";

import Contact from "../../footer/askus";

import RegisterUser from "./registerlogin/RegisterUser";
import ChangePassword from "./registerlogin/ChangePassword";
import Loginscreen from "./registerlogin/LoginScreen";
import Login from "./registerlogin/Login";
import Logout from "./registerlogin/Logout";

import ServiceSubscription from "./dashboard/ServiceSubscriptionRadcasts";
import ThankYouUploaded from "./pages/evententities/createanevent/ThankyouAfterUploaded";
import UploadAssets from "./pages/evententities/createanevent/CreateEventBase";
import UploadPictures from "./pages/evententities/createanevent/UploadPictures";
import UploadVideo from "./pages/evententities/createanevent/UploadVideo";

import AssetDetailsPageDev from "./pages/landingPages/AssetDetailPageDev";
import AssetDetailsPageLive from "./pages/landingPages/AssetDetailPageLive";


import ThankyouAfterRegistration from "./registerlogin/ThankyouAfterRegistration";
import ThankyouPasswordChanged from  "./registerlogin/ThankyouPasswordChanged";

import BuyTicket from "./booking/BuyATicket";
import CollectAnInviteAsVIP from "./booking/CollectAVIPInvite";
import CollectInvite from "./booking/CollectInvite";
import ConfirmParticipationToEvent from "./booking/CollectInvite";

import CreateOrdinaryInvite from "./components/CreateanEventOrdinaryInvite";
import CreateVIPInvite from "./components/CreateanEventVIPInvite";
import CreateEventTicket from "./components/CreateanEventTicket";

import DisplayInvite from "./components/DisplayInvite";
import DisplayPerformance from "./components/DisplayPerformance";
import DisplayEventTicket from "./components/DisplayEventTicket";

import ManageEvent from './dashboard/ManageEventComponent';
import DisplayMyDashboard from './dashboard/DisplayMyDashboard';
import SeeParticipants from "./dashboard/DisplayParticipants";
import Help from "./help";
import Visits from "./UserVisits";
import GeneratePerformance from "./pages/performancesandorders/Performances/GeneratePerformance";
import Performances from "./pages/performancesandorders/Performances/PerformancePage";

class RadioBroadcastsBody
 extends Component {
  render() {
    return (
        <HashRouter> 
          <Route path="/helpradcasts" component={Help} />      
          <Route path="/visitsradcasts" component={Visits} />              
          <Route path="/loginscreenradcasts" component={Loginscreen} />   
          <Route path="/contactradcasts" component={Contact} />     
          <Route path="/registeruserradcasts" component={RegisterUser} />
          <Route path="/performancesradcasts" component={Performances} /> 
          <Route path="/generateperformanceradcasts" component={GeneratePerformance} /> 
          <Route path="/changepasswordradcasts" component={ChangePassword} /> 
          <Route path="/loginradcasts" component={Login} /> 
          <Route path="/logoutradcasts" component={Logout} /> 
          <Route path="/servicesubradcasts" component={ServiceSubscription} /> 
          <Route path="/thankyouuploadedradcasts" component={ThankYouUploaded} /> 
          <Route path="/uploadassetsradcasts" component={UploadAssets} />
          <Route path="/uploadpicturesradcasts" component={UploadPictures} />
          <Route path="/uploadvideosradcasts" component={UploadVideo} />          
          <Route path="/detailsdevradcasts" component={AssetDetailsPageDev} />
          <Route path="/detailsradcasts" component={AssetDetailsPageLive} />
          <Route path="/thankyouafterregistrationradcasts" component={ThankyouAfterRegistration} />
          <Route path="/thankyoupasswordchangedradcasts" component={ThankyouPasswordChanged} />
          <Route path="/buyticketradcasts" component={BuyTicket} />
          <Route path="/collectaninviteasvipradcasts" component={CollectAnInviteAsVIP} /> 
          <Route path="/collectordinaryinviteradcasts" component={CollectInvite} />
          <Route path="/confirmparticipationtoeventradcasts" component={ConfirmParticipationToEvent} />
          <Route path="/createordinaryinviteradcasts" component={CreateOrdinaryInvite} />
          <Route path="/createvipinviteradcasts" component={CreateVIPInvite} />
          <Route path="/createeventticketradcasts" component={CreateEventTicket} />
          <Route path="/displayinviteradcasts" component={DisplayInvite} />
          <Route path="/displayperformanceradcasts" component={DisplayPerformance} />
          <Route path="/displayeventticketradcasts" component={DisplayEventTicket} />
          <Route path="/manageeventradcasts" component={ManageEvent} />
          <Route path="/displaymydashboardradcasts" component={DisplayMyDashboard} /> 
          <Route path="/seeparticipantsradcasts" component={SeeParticipants} />
          <Route />
        </HashRouter>
     
    );
  }
}

export default withTranslation()(RadioBroadcastsBody);
