import React, { useState, useEffect } from "react";
import axios from "axios";
import {NavLink, HashRouter } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,  
} from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { useTranslation  } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import GetUrl from "../../tvBroadcastServices/urlServicetvBroadcasts";
import GetEnvironment  from "../../tvBroadcastServices/getEnvironment";
import LocalStorageService from '../../../../services/localStorageService';
import ReactPlayer from "react-player";
//https://codesandbox.io/s/c53q2?file=/src/App.js:58-172
import Comments from "../../../comments/comments";
import CreateComment from "../../../comments/createComment";

const AssetDetailPageLive = (props) => { 
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [eventNote, setEventNote] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [propertyName, setpropertyName]= useState('');
  const [mainImagesrc, setMainImagesrc] = useState();
  const [firstOptsrc, setFirstOptsrc] = useState();
  const [secondOptsrc, setSecondOptsrc] = useState();
  const [thirdOptsrc, setThirdOptsrc] = useState();
  const [videoPath, setVideoPath] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [videoPathIsNullOrEmpty, setVideoPathStatus] = useState(false);
  const [hasMenue, setHasMenue] = useState();
  const [post, setPost] = useState([]); 
  const [displaycarousel, setdisplaycarousel] = useState(false);  
  const[videofromApi, setvideofromApi]= useState();
  const [env,  setEnv] = useState('')
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const[sellerPhoneNumber, setSellerPhoneNumner]= useState();
  const[address, setAddress ]= useState();
  const[sellerName, setSellerName ]= useState();
  const[startDate, setStartDate ]= useState();
  const[endDate, setEndDate ]= useState();
  const[startTime, setStartTime]= useState();
  const[endTime, setEndTime]= useState();
  const[organiserFullName, setOrganiserFullName]= useState();
  const[participationStatus, setParticipationStatus]= useState();  
 
  useEffect(() => { 
    setTimeout(() =>  getAssetDetailsById(), 100);
  }, []);

  const { t } = useTranslation();
   
  useEffect(() => {
    setEnv(LocalStorageService("get", "environment")??GetEnvironment())
  }, []) ;

  useEffect(() => {  
    setIsVideoLoaded(true);  
}, []);

  function getApiPath () {     
    return GetUrl("getAssetDetailsById");   
  }
  
  // useEffect(() => {
  //   LocalStorageService("clear");
  //   }, []);
    
    useEffect(() => {
      setTimeout(() =>  LocalStorageService("set", "prodid", window.location.href.split('?')[1]), 100);      
    }, []) ;
    
const prodid = window.location.href.split('?')[1];

  const  getAssetDetailsById = () =>{   
    const url = getApiPath();   
    axios.post(url, {         
         Id: prodid
    })
          .then(res => {   
            if(res.data !=="no data") 
            {
              setPost(res.data);
            }
       
  //uncomment from here 
  LocalStorageService("set","videoApi", res.data[0].Videopath);
  setvideofromApi(res.data[0].Videopath);
  LocalStorageService("set","assetNoteApi", res.data[0].Sellernote);
  LocalStorageService("set","assetDescriptionApi", res.data[0].Description);
  LocalStorageService("set","assetNameApi", res.data[0].Venue);
  LocalStorageService("set","assetPriceApi", res.data[0].Price);
  LocalStorageService("get","productidApi")??LocalStorageService("set","productidApi", res.data[0].productId); 
  LocalStorageService("set","prodNameApi", res.data[0].productName); 
  LocalStorageService("set","hasMenueApi", res.data[0].MenueStatus);    
  LocalStorageService("set", "sellerPhoneNumberApi",res.data[0].SellerPhone);
  LocalStorageService("set", "sellerEmailApi",res.data[0].SellerEmail);
  LocalStorageService("set", "shopNameApi",res.data[0].Venue);
  LocalStorageService("set", "sellerNameApi",res.data[0].InserterName );  
 
  const productNamefromApi = res.data[0].productName; 
  const hasMenuefromApi = res.data[0].MenueStatus;    
  const sellerPhoneNumberfromApi = res.data[0].SellerPhone;
  const sellerEmailfromApi = res.data[0].SellerEmail;
  const addressfromAPi  = res.data[0].Address;
  const sellerNamefromApi  = res.data[0].SellerName;
  const startDatefromAPi  = res.data[0].startDate;
  const endDatefromApi  = res.data[0].endDate;
  const organiserFullNamefromApi  = res.data[0].Organiser;
  const participationfromApi  = res.data[0].ParticipationStatus;
  const sellerNoteFromApi = res.data[0].Sellernote;

  LocalStorageService("set","organiserFullName", organiserFullNamefromApi);
     const propName = productNamefromApi;
     const isMenueAvailable = hasMenuefromApi;  
     const mainimage = `/${res.data[0].PathMainImage}`;
     const opt1image = `/${res.data[0].PathFirstOptionalImage}`;
     const opt2image = `/${res.data[0].PathSecondOptionalImage}`;
     const opt3image = `/${res.data[0].PathThirdOptionalImage}`;
    
  let isNullOrEmptyvideoPath = videoPath===''||videoPath===null;
   setVideoPathStatus(isNullOrEmptyvideoPath); 
   setdisplaycarousel(true);
   var videosrc =  videofromApi?? "videos/thesun.earthrotating.mp4video.mp4"
   const video = `/${res.data[0].Videopath}`;
   setpropertyName(propName);
   setEventNote(sellerNoteFromApi); 
   setEventDescription(eventDescription); 
   setSellerPhoneNumner(sellerPhoneNumberfromApi);
   setAddress(addressfromAPi);
   setSellerName(sellerNamefromApi);
   setContactEmail(sellerEmailfromApi);     
   setHasMenue(isMenueAvailable);   
   setStartDate(startDatefromAPi.toString().split(" ")[0]);     
   setEndDate(endDatefromApi.toString().split(" ")[0]);
   setStartTime((startDatefromAPi.toString().split(" ")[1]).substring(0,5));     
   setEndTime((endDatefromApi.toString().split(" ")[1]).substring(0,5)); 
   setMainImagesrc(mainimage); 
   setFirstOptsrc(opt1image); 
   setSecondOptsrc(opt2image);  
   setThirdOptsrc(opt3image); 
   setVideoPath(video);
   setOrganiserFullName(organiserFullNamefromApi);
   setParticipationStatus(participationfromApi);

       })
       .then(()=>{
       })
     .catch((e) => console.log(e))    
  }

  const dimensions = {
    width:"100%",
    height:"100%",
  }
  const items = [      
    {   
      src: mainImagesrc,//image1,    
      altText: "Slide 1",
      caption: "",   
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: firstOptsrc,
      altText: "Slide 2",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: secondOptsrc,
      altText: "Slide 3",
      caption: "",    
      width:dimensions.width,
      height:dimensions.height,
    },
    {
      src: thirdOptsrc,
      altText: "Slide 4",
      caption: "",     
      width:dimensions.width,
      height:dimensions.height,    
    }
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

/*https://stackoverflow.com/questions/52795642/change-the-background-image-dynamically*/
const slides = items.filter(img => img).map((item) => { 
  const  backgroundStyle = {
    color: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: "100%",
    height: "500px",
    backgroundImage: `url(${item.src})` 
 };    

  return (  
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.src}
    >        
   <CarouselCaption
        captionText={item.caption}         
      />
     <div style={backgroundStyle}> </div> 
     <div> </div>        
    </CarouselItem>
  );
});

  ///////
  ///End CAROUSEL////Carousel 
  return (  
    <div className="Container-detail-page">
      <Row>
      <Col>
         <div className="item-detailed-description">  <p>Event identification number: {prodid}</p> 
         <p>Here below is the defendant's video</p>
         </div>
        
         {!videoPathIsNullOrEmpty &&(
            <div className="body-pannel">                    
             <div className="playerWrapper" style={{opacity: isVideoLoaded ? 1 : 0}}>       
               <ReactPlayer
                 url={videoPath}
                 playing={true}
                 controls={true}
                 loop={false}
                 muted={true}
                 playsinline={true}
                 onReady={isVideoLoaded}
               />
            
             </div>
       </div>
       )}
       </Col>  

      </Row>
      <p></p>  
    <p></p>
    <Row> 
    <Col md={{ span: 6, offset: 3 }}>
    <div className="item-detailed-description"> 
       <span className = "detailed-item-description">
        <p>TV Broadcast info</p>
       </span>  
       <span>        
         <p><b> Name: </b> {organiserFullName??sellerName}</p>
         {/* <p><b> Phone:</b> {sellerPhoneNumber}</p>
         <p><b> Email: </b> {contactEmail}</p>         */}
       </span>
       <span>
        <p><b><b>Post  information </b></b> </p>
        </span>
        <span>
        <p><b>Who posted email: </b> {contactEmail}</p> 
        <p><b>Who posted contact: </b> {sellerPhoneNumber}</p>  
        <p><b>Subject: </b> {propertyName}</p>        
        <p><b>When: </b>{startDate} <b>at local time: </b>{startTime}</p>
        <p><b>Issue number: </b>{startDate} <b>at local time: </b>{startTime}</p>
        {/* <p><b>To: </b> {endDate} <b>at local time: </b>{endTime}</p> */}
        <p><b>Description: </b></p> 
        <div className="container" >
        <p>{eventDescription}</p>  
        {displaycarousel &&(<Row>
    <Col >
      <div className="container carousel-container">
      <span className = "detailed-item-identification-number">   
     </span>
    <Carousel  className="carousel-panel" activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
    </div>
    </Col>    
    </Row> )}
    </div>       
      </span> 
      <p></p>
       <span>
         <p Style="color:red"><b>My come back </b> </p> 
         <div className = "container" Style="background-color:cyan; width:auto; min-height:100px ">
         <p>{eventNote}</p>
         </div>       
      </span>  
     </div>       
    </Col>
    </Row> 
    <p></p> 
    <p></p> 

    <Row> 
    <div className="container item-detailed-description">  
    <Col md={{ span: 6, offset: 3 }}>
    <b><p>Comments</p> </b>
    <Comments></Comments>
    </Col>
    </div>    
    </Row> 
    <Row>
    <p></p> 
    <p></p>  
  <div  className="content-akwaba">
  
   <Accordion defaultActiveKey="0"> 

{hasMenue==0 &&( 
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
   Make a comment
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body className="content-accordion" >     
         <CreateComment></CreateComment>
        </Card.Body>
    </Accordion.Collapse>
  </Card>
)}
  </Accordion>
 
  </div>
    </Row>
    <p></p>     
    </div>    
  );
};

export default (AssetDetailPageLive);
