import {useState, useEffect} from 'react';
import config from '../config.json';
import GetEnvironment from './getEnvironment';

// function baseUrl(){   
//     switch(GetEnvironment()){
//       case "dev":
//       return config.TEST_BASE ;//"groupakwabatech.com",      
//       case "live":
//       return  config.BASE_URL_LOCAL ;
//       case "test":
//       return config.BASE_URL_LIVE ;      
//     }
//     }
 
 function getContactUsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.MESSAGES.ContactUs;
        case "live":
        return  config.DIRECT_LIVE.MESSAGES.ContactUs;
        case "test":
        return  config.DIRECT_TEST.MESSAGES.ContactUs;
        }
}


function getCommentsByIdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.COMMENTS.RetrieveCommentsById;
        case "live":
        return  config.DIRECT_LIVE.COMMENTS.RetrieveCommentsById;
        case "test":
        return  config.DIRECT_TEST.COMMENTS.RetrieveCommentsById;
        }
}

function createCommentByTopicUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.COMMENTS.CreateCommentsByTopic;
        case "live":
        return  config.DIRECT_LIVE.COMMENTS.CreateCommentsByTopic;
        case "test":
        return  config.DIRECT_TEST.COMMENTS.CreateCommentsByTopic;
        }
}




function RetrieveBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.RetrieveBookings;
        case "live":
        return  config.DIRECT_LIVE.RetrieveBookings;
        case "test":
        return  config.DIRECT_TEST.RetrieveBookings;
        }
    }

function getBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.BOOKINGS;
        case "live":
        return  config.DIRECT_LIVE.BOOKINGS;
        case "test":
        return  config.DIRECT_TEST.BOOKINGS;
        }
    }

 function retrieveMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.MESSAGES.RetrieveMyMessages;
        case "live":
        return config.DIRECT_LIVE.MESSAGES.RetrieveMyMessages;
        case "test":
        return config.DIRECT_TEST.MESSAGES.RetrieveMyMessages;
        }

}

function RetrievecontactmessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.MESSAGES.RetrieveContactMessages;
        case "live":
        return config.DIRECT_LIVE.MESSAGES.RetrieveContactMessages;
        case "test":
        return config.DIRECT_TEST.MESSAGES.RetrieveContactMessages;
        }

}


function RetrieveinterestmessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.BOOKINGS.RetrieveInterests;
        case "live":
        return config.DIRECT_LIVE.BOOKINGS.RetrieveInterests;
        case "test":
        return config.DIRECT_TEST.BOOKINGS.RetrieveInterests;
        }

}

 function getAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.BOOKINGS.Appointments;
        case "live":
        return config.DIRECT_LIVE.BOOKINGS.Appointments;
        case "test":
        return config.DIRECT_TEST.BOOKINGS.Appointments;
        }
    
}
 function retrieveBookingsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.BOOKINGS.RetrieveBookings;
        case "live":
        return config.DIRECT_LIVE.BOOKINGS.RetrieveBookings;
        case "test":
        return config.DIRECT_TEST.BOOKINGS.RetrieveBookings;
        }

}


function retrieveInterestsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.BOOKINGS.RetrieveInterests;
        case "live":
        return config.DIRECT_LIVE.BOOKINGS.RetrieveInterests;
        case "test":
        return config.DIRECT_TEST.BOOKINGS.RetrieveInterests;
        }

}


function retrieveAppointmentsUrl(){
    switch(GetEnvironment()){
        case "dev":
           return config.LOCALHOST_DEV.BOOKINGS.RetrieveAppointments;
        case "live":
            return config.DIRECT_LIVE.BOOKINGS.RetrieveAppointments;
        case "test":
            return config.DIRECT_TEST.BOOKINGS.RetrieveAppointments;
        }

}


 function registerSimpleUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.REGISTERLOGIN.RegisterUser;
        case "live": 
        return config.DIRECT_LIVE.REGISTERLOGIN.RegisterUser; 
        case "test":  
        return config.DIRECT_TEST.REGISTERLOGIN.RegisterUser; 
        }      

}

function resetPasswordUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.REGISTERLOGIN.ResetPassword;
        case "live": 
        return config.DIRECT_LIVE.REGISTERLOGIN.ResetPassword; 
        case "test":  
        return config.DIRECT_TEST.REGISTERLOGIN.ResetPassword; 
        }      

}


 function loginUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.REGISTERLOGIN.Login;
        case "live": 
        return config.DIRECT_LIVE.REGISTERLOGIN.Login;
        case "test":
        return config.DIRECT_TEST.REGISTERLOGIN.Login;
    }

}

function SearchDashboardUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.SEARCH.SearchDashboard;
        case "live":
        return config.DIRECT_LIVE.SEARCH.SearchDashboard;
        case "test":
        return config.DIRECT_TEST.SEARCH.SearchDashboard;
        }
    
}

function SearchDashboardByNameUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.SEARCH.SearchDashboardByName;
        case "live":
        return config.DIRECT_LIVE.SEARCH.SearchDashboardByName;
        case "test":
        return config.DIRECT_TEST.SEARCH.SearchDashboardByName;
        }
    
}



 function loginManagerUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.REGISTERLOGIN.LoginManager;
        case "live":
        return config.DIRECT_LIVE.REGISTERLOGIN.LoginManager;
        case "test":
        return config.DIRECT_TEST.REGISTERLOGIN.LoginManager;
        }
    
}

 function logoutUrl(){
    switch(GetEnvironment()){
        case "dev":
        return  config.LOCALHOST_DEV.REGISTERLOGIN.Logout;
        case "live":
        return  config.DIRECT_LIVE.REGISTERLOGIN.Logout;
        case "test":
        return  config.DIRECT_TEST.REGISTERLOGIN.Logout;
        }
    
}
 function insertAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.InsertAsset;
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.InsertAsset;
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.InsertAsset;
        }
}

 function retrieveAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.RetrieveAsset;
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.RetrieveAsset;
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.RetrieveAsset;
        }
}
 function displayAssetUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.DisplayAsset;    
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.DisplayAsset;    
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.DisplayAsset; 
        }

}

 function uploadVideoUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.UploadMkDirVideo; 
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.UploadMkDirVideo; 
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.UploadMkDirVideo;
        } 
}

function searchUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.SEARCH.Search; 
        case "live":
        return config.DIRECT_LIVE.SEARCH.Search; 
        case "test":
        return config.DIRECT_TEST.SEARCH.Search;
        } 
}

function generateInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.INVOICES.GenerateInvoice; 
        case "live":
        return config.DIRECT_LIVE.INVOICES.GenerateInvoice; 
        case "test":
        return config.DIRECT_TEST.INVOICES.GenerateInvoice;
        }        
}

function getInvoiceUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.INVOICES.GetInvoice; 
        case "live":
        return config.DIRECT_LIVE.INVOICES.GetInvoice;; 
        case "test":
        return config.DIRECT_TEST.INVOICES.GetInvoice;;
        }        
}

function getInvoiceByNumberUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.INVOICES.GetInvoiceByNumber; 
        case "live":
        return config.DIRECT_LIVE.INVOICES.GetInvoiceByNumber; 
        case "test":
        return config.DIRECT_TEST.INVOICES.GetInvoiceByNumber;
        }        
}



function getAssetDetailByIdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.GetAssetDetailsById; 
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.GetAssetDetailsById; 
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.GetAssetDetailsById;
        }        
}



function getPaymentCardUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.PAYMENT.RetrievePaymentDetails; 
        case "live":
        return config.LOCALHOST_DEV.PAYMENT.RetrievePaymentDetails; 
        case "test":
        return config.LOCALHOST_DEV.PAYMENT.RetrievePaymentDetails;
        }        
}

function sendPaymentConfirmationdUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.PAYMENT.SendPaymentConfirmation; 
        case "live":
        return config.DIRECT_LIVE.PAYMENT.SendPaymentConfirmation; 
        case "test":
        return config.DIRECT_TEST.PAYMENT.SendPaymentConfirmation;
        }        
}



function generateOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.ORDERS.GenerateOrder; 
        case "live":
        return config.DIRECT_LIVE.ORDERS.GenerateOrder; 
        case "test":
        return config.DIRECT_TEST.ORDERS.GenerateOrder;
        }        
}

function getOrderUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.ORDERS.GetOrder; 
        case "live":
        return config.DIRECT_LIVE.ORDERS.GetOrder; 
        case "test":
        return config.DIRECT_TEST.ORDERS.GetOrder;
        }        
}


// function generateFoodMenueUrl(){
//     switch(GetEnvironment()){
//         case "dev":
//         return config.LOCALHOST_DEV.GENERATEFOODMENUE; 
//         case "live":
//         return config.DIRECT_LIVE.GENERATEFOODMENUE; 
//         case "test":
//         return config.DIRECT_TEST.GENERATEFOODMENUE;
//         }        
// }

// function getFoodMenueAsAChefUrl(){
//     switch(GetEnvironment()){
//         case "dev":
//         return config.LOCALHOST_DEV.GENERATEFOODMENUEASACHEF; 
//         case "live":
//         return config.DIRECT_LIVE.GENERATEFOODMENUEASACHEF; 
//         case "test":
//         return config.DIRECT_TEST.GENERATEFOODMENUEASACHEF;
//         }        
// }


// function getFoodMenueUrl(){
//     switch(GetEnvironment()){
//         case "dev":
//         return config.LOCALHOST_DEV.GETFOODMENUE; 
//         case "live":
//         return config.DIRECT_LIVE.GETFOODMENUE; 
//         case "test":
//         return config.DIRECT_TEST.GETFOODMENUE;
//         }        
// }


function uploadPicsUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.CREATEEVENT.UploadMkDirPics; 
        case "live":
        return config.DIRECT_LIVE.CREATEEVENT.UploadMkDirPics; 
        case "test":
        return config.DIRECT_TEST.CREATEEVENT.UploadMkDirPics;
        } 
}


function retrieveBuyerMessagesUrl(){
    switch(GetEnvironment()){
        case "dev":
        return config.LOCALHOST_DEV.MESSAGES.RetrieveBuyerMessages;
        case "live":
        return config.DIRECT_LIVE.MESSAGES.RetrieveBuyerMessages;
        case "test":
        return config.DIRECT_TEST.MESSAGES.RetrieveBuyerMessages;
        }
}

function RetrieveTicketByIdUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.Tickets.RetrieveTicketById; 
        case "live":

        return config.DIRECT_LIVE.Tickets.RetrieveTicketById; 
        case "test":

        return config.DIRECT_TEST.Tickets.RetrieveTicketById;
        } 
}


function RetrieveretrieveAssetsMixdUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.Mix.RetrieveretrieveAssetsMix; 
        case "live":

        return config.DIRECT_LIVE.Mix.RetrieveretrieveAssetsMix; 
        case "test":

        return config.DIRECT_TEST.Mix.RetrieveretrieveAssetsMix;
        } 
}

function RetrievePaymentValueByTicketIdUrl(){
    switch(GetEnvironment()){
        case "dev":

        return config.LOCALHOST_DEV.PAYMENT.RetrievePaymentValueByTicketId; 
        case "live":

        return config.DIRECT_LIVE.PAYMENT.RetrievePaymentValueByTicketId; 
        case "test":

        return config.DIRECT_TEST.PAYMENT.RetrievePaymentValueByTicketId;
        } 
}


export default function GetUrl(targetUrl){
    switch(targetUrl){
        case "retrieveAssetsMix":
            return  RetrieveretrieveAssetsMixdUrl(); 
    
        case "retrievePaymentValueByTicketId":
            return  RetrievePaymentValueByTicketIdUrl(); 

        case "retrieveticketbyid":
            return RetrieveTicketByIdUrl(); 
    
        case "contactUs":
        return getContactUsUrl(); 

        case "retrieveMessages":
            return retrieveMessagesUrl(); 

        case "Retrievecontactmessages":
            return RetrievecontactmessagesUrl(); 

         case "Retrieveinterestmessages":
            return RetrieveinterestmessagesUrl();             
            
        case "retrieveBuyerMessages":
            return retrieveBuyerMessagesUrl(); 

        case "bookings":
        return getBookingsUrl(); 

        case "appointments":
            return getAppointmentsUrl(); 
        
        case "retrieveBookings":
            return retrieveBookingsUrl(); 

        case "retrieveInterests":
            return retrieveInterestsUrl(); 

        case "retrieveAppointments":
            return retrieveAppointmentsUrl(); 

        case "registerSimple":
        return registerSimpleUrl();

        case "resetPassword":
        return resetPasswordUrl();

        case "searchDashboard":
        return  SearchDashboardUrl(); 

        case "searchDashboardByName":
        return  SearchDashboardByNameUrl(); 

        case "login":
        return loginUrl(); 

        case "loginManager":
        return loginManagerUrl();

        case "logout":
        return logoutUrl(); 

        case "insertAsset":
        return insertAssetUrl();

        case "retrieveAsset":
        return retrieveAssetUrl();

        case "displayAsset":
        return displayAssetUrl();

        case "uploadPics":
        return uploadPicsUrl(); 

        case "uploadVideo":
        return uploadVideoUrl();  
        
        case "search":
        return searchUrl();

        case "generateInvoice":
            return generateInvoiceUrl();

        case "retrieveInvoices":
            return getInvoiceUrl(); 

            case "retrieveInvoiceByNumber":
                return getInvoiceByNumberUrl();            

        // case "generateFoodMenue":
        //     return generateFoodMenueUrl();
    
        // case "retrieveFoodMenue":
        //     return getFoodMenueUrl();
          
        // case "retrieveFoodMenueAsAChef":
        //     return getFoodMenueAsAChefUrl();
            
        
        case "generateOrder":
            return generateOrderUrl();
        
            case "retrieveOrder":
                return getOrderUrl(); 

        case "getAssetDetailsById":
            return getAssetDetailByIdUrl(); 

        case "getPaymentCard":
            return getPaymentCardUrl(); 
          
            case "getPaymentCard":
                return getPaymentCardUrl(); 

        case "sendPaymentConfirmation":
            return sendPaymentConfirmationdUrl(); 
            
        case "getCommentsById":
                return getCommentsByIdUrl(); 

        case "createCommentByTopic":
            return createCommentByTopicUrl();   
                
        }    
}
