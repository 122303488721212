
//https://www.itsolutionstuff.com/post/password-and-confirm-password-validation-in-reactexample.html

import { useTranslation } from 'react-i18next';
import { React,useState, useEffect} from "react";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import GetUrl from "../../../socialMediasServices/urlServiceSocialMedias";
import LocalStorageService from '../../../../../services/localStorageService';
import {Row, Col} from 'react-bootstrap';
 
  const GeneratePerformance= () => {

    LocalStorageService("remove","servicecategory");
    LocalStorageService("set","servicecategory","party");
    
  const [title, setTitle] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [businessName, setBusinessName] = useState(''); 
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [item1Venue, setItem1Venue] = useState(''); 
  const [item2Venue, setItem2Venue] = useState(''); 
  const [item3Venue, setItem3Venue] = useState('');
  const [item4Venue, setItem4Venue] = useState('');
  const [item5Venue, setItem5Venue] = useState('');  
  const [item6Venue, setItem6Venue] = useState('');


  const [item1Name, setItem1Name] = useState('');
  const [item1Price, setItem1Price] = useState('');
  const [item2Name, setItem2Name] = useState('');
  const [item2Price, setItem2Price] = useState('');
  const [item3Name, setItem3Name] = useState('');
  const [item3Price, setItem3Price] = useState('');
  const [item4Name, setItem4Name] = useState('');
  const [item4Price, setItem4Price] = useState('');
  const [item5Name, setItem5Name] = useState('');
  const [item5Price, setItem5Price] = useState('');
  const [item6Name, setItem6Name] = useState('');
  const [item6Price, setItem6Price] = useState(''); 
  const [eventName, setEventName] = useState('');
  const [firstNameErrorMessage,  setErrorMessageFirstName] = useState('');
  const [lastNameErrorMessage,  setErrorMessageLastName] = useState('');
  const [titleErrorMessage,  setErrorMessageTitle] = useState('');
  const [customerPhoneNumberErrorMessage, setCustomerPhoneNumberErrorMessage] = useState('');
  const [contactEmailErrorMessage,  setErrorMessageContactEmail] = useState('');
  const [contactPhoneErrorMessage,  setErrorMessageContactPhone] = useState('');
  const [item1NameErrorMessage,  setErrorMessageItem1Name] = useState('');
  const [item1PriceErrorMessage,  setErrorMessageItem1Price] = useState('');
  const [item2NameErrorMessage,  setErrorMessageItem2Name] = useState('');
  const [item2PriceErrorMessage,  setErrorMessageItem2Price] = useState('');
  const [item3NameErrorMessage,  setErrorMessageItem3Name] = useState('');
  const [item3PriceErrorMessage,  setErrorMessageItem3Price] = useState('');
  const [item4NameErrorMessage,  setErrorMessageItem4Name] = useState('');
  const [item4PriceErrorMessage,  setErrorMessageItem4Price] = useState('');
  const [item5NameErrorMessage,  setErrorMessageItem5Name] = useState('');
  const [item5PriceErrorMessage,  setErrorMessageItem5Price] = useState('');
  const [item6NameErrorMessage,  setErrorMessageItem6Name] = useState('');
  const [item6PriceErrorMessage,  setErrorMessageItem6Price] = useState(''); 
  const [errorMessageBusinessName,   setErrorMessageBusinessName] = useState('');  
  const [item1Qty,  setItem1Qty] = useState(''); 
  const [item1QtyErrorMessage,  setErrorMessageItem1Qty] = useState(''); 
  const [item2Qty,  setItem2Qty] = useState(''); 
  const [item2QtyErrorMessage,  setErrorMessageItem2Qty] = useState(''); 
  const [item3Qty,  setItem3Qty] = useState(''); 
  const [item3QtyErrorMessage,  setErrorMessageItem3Qty] = useState(''); 
  const [item4Qty,  setItem4Qty] = useState(''); 
  const [item4QtyErrorMessage,  setErrorMessageItem4Qty] = useState(''); 
  const [item5Qty,  setItem5Qty] = useState(''); 
  const [item5QtyErrorMessage,  setErrorMessageItem5Qty] = useState(''); 
  const [item6Qty,  setItem6Qty] = useState(''); 
  const [item6QtyErrorMessage,  setErrorMessageItem6Qty] = useState(''); 

  const [item1VenueErrorMessage,  setErrorMessageItem1Venue] = useState(''); 
  const [item2VenueErrorMessage,  setErrorMessageItem2Venue] = useState(''); 
  const [item3VenueErrorMessage,  setErrorMessageItem3Venue] = useState(''); 
  const [item4VenueErrorMessage,  setErrorMessageItem4Venue] = useState(''); 
  const [item5VenueErrorMessage,  setErrorMessageItem5Venue] = useState(''); 
  const [item6VenueErrorMessage,  setErrorMessageItem6Venue] = useState(''); 

  const [eventNameErrorMessage,  setErrorMessageEventName] = useState('');
  const [clickedButtonButNotPosted, setClickedButtonButNotPosted] = useState('');
  const [clickedNotPostedMessage, setClickedNotPostedMessage] = useState('');
  const [state, setState] = useState(false);
  const [note, setNote] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [viewItem2, setViewItem2] = useState(false);
  const [viewItem3, setViewItem3] = useState(false);
  const [viewItem4, setViewItem4] = useState(false);
  const [viewItem5, setViewItem5] = useState(false);
  const [viewItem6, setViewItem6] = useState(false);
  const [viewItem2Button, setViewItem2Button] = useState(true);
  const [viewItem3Button, setViewItem3Button] = useState(false);
  const [viewItem4Button, setViewItem4Button] = useState(false);
  const [viewItem5Button, setViewItem5Button] = useState(false);
  const [viewItem6Button, setViewItem6Button] = useState(false);
  const [viewSubmitButton, setViewSubmitButton] = useState(true);
  const [viewCancel, setViewCancel] = useState(false);
  const [viewSave, setViewSave] = useState(false);
  const [viewAddMoreItem, setViewAddMoreItem] = useState(true);
  const [category, setCategory] = useState(); 
  const [eventNumber, setEventNumber] = useState(); 
 
  const { t } = useTranslation();

  function getApiPath () { 
    return GetUrl("generatePerformance")
  };

  function loginToken (){
    return LocalStorageService("get", "token")
  }; 
 

  useEffect (() =>{
    setContactEmail(LocalStorageService("get", "sellerEmail"))
  }, )
 const token = loginToken();

  const isLoggedin = token!=null;

  function execute (event)  {
    event.preventDefault();  
      console.info("Valid Form");  
      handleSave(event);    
    
  };

  const  handleCancel = () =>{
    setViewCancel(false);
    setViewSubmitButton(true);
    setViewSave(false);
    setViewAddMoreItem(true);
      }
       

  const handleSubmit = () => {
    setViewCancel(true);
    setViewSubmitButton(false);
    setViewSave(true);
    setViewAddMoreItem(false);
  }

  const  handleSave = (event) =>{
    console.log('handleSave ran');
 // setEventNumber(window.location.href.split('?')[1])
   event.preventDefault(); // 👈️ prevent page refresh
   const body ={
   title,
   fname,
   lname,
   customerPhoneNumber,
   businessName,
   contactEmail,  
   contactPhone,
   category, 
   item1Name, 
   item1Price,   
   item2Name, 
   item2Price,   
   item3Name, 
   item3Price,  
   item4Name, 
   item4Price,  
   item5Name, 
   item5Price,  
   item6Name,  
   item6Price,
   eventName,
   item1Qty,
   item2Qty,
   item3Qty,
   item4Qty,
   item5Qty,
   item6Qty,
   item1Venue,
   item2Venue,
   item3Venue,
   item4Venue,
   item5Venue,
   item6Venue
}; 
    
  validate();

   setClickedButtonButNotPosted(<p>{t("pages.contact.text.msginvalidform")} </p>);
     
      const API_PATH = getApiPath(); 
      if(token) {
      axios({
        method: "post",     
        url: `${API_PATH}`,
        data: body,
      })
        .then((result) => {
          if (result.status === 200) { 
            console.log({result})
           setMessageSent(true); 
           LocalStorageService("set", "email", contactEmail);
           LocalStorageService("set", "insertedId", result.data);        
           setBusinessName(''); 
           setCategory('');  
           setEventNumber('');         
           setContactEmail('');
           setContactPhone('');
           setItem1Name('');
           setItem1Price('');
           setItem2Name('');
           setItem2Price('');
           setItem3Name('');
           setItem3Price('');
           setItem4Name('');
           setItem4Price('');
           setItem5Name('');
           setItem5Price('');
           setItem6Name('');
           setItem6Price('');         
           setEventName(''); 
           setItem1Qty('');
           setItem2Qty('');
           setItem3Qty('');
           setItem4Qty('');
           setItem5Qty('');
           setItem6Qty(''); 
           setFirstName('');
           setItem1Venue('');
           setItem2Venue('');
           setItem3Venue('');
           setItem4Venue('');
           setItem5Venue('');
           setItem6Venue('');
           setLastName('');
           setTitle('');
           setCustomerPhoneNumber('');      
          setErrorMessageFirstName('');
          setErrorMessageLastName('');
          setErrorMessageTitle('');
          setErrorMessageContactEmail('');
          setErrorMessageContactPhone('');
          setErrorMessageItem1Name('');
          setErrorMessageItem1Price('');
          setErrorMessageItem2Name('');
          setErrorMessageItem2Price('');
          setErrorMessageItem3Name('');
          setErrorMessageItem3Price('');
          setErrorMessageItem4Name('');
          setErrorMessageItem4Price('');
          setErrorMessageItem5Name('');
          setErrorMessageItem5Price('');
          setErrorMessageItem6Name('');
          setErrorMessageItem6Price('');          
          setErrorMessageEventName('');
          setErrorMessageItem1Qty('');
          setErrorMessageItem2Qty('');
          setErrorMessageItem3Qty('');
          setErrorMessageItem4Qty('');
          setErrorMessageItem5Qty('');
          setErrorMessageItem6Qty('');
          setErrorMessageItem1Venue('');
          setErrorMessageItem2Venue('');
          setErrorMessageItem3Venue('');
          setErrorMessageItem4Venue('');
          setErrorMessageItem5Venue('');
          setErrorMessageItem6Venue('');
          }
        })
        .catch(function (error) {    
          console.log(error);
        });  
      }   
    
  }

  const handleClick1 = ()=>{
   setViewItem2(true);
   setViewItem3Button(true);  
   setViewItem2Button(false);    
  } 
   const handleClick2 = ()=>{
    setViewItem3(true);
    setViewItem4Button(true);   
    setViewItem3Button(false);  
   }
   const handleClick3 = ()=>{
    setViewItem4(true); 
    setViewItem5Button(true);   
    setViewItem4Button(false);  
   }
   const handleClick4 = ()=>{
    setViewItem5(true);
    setViewItem6Button(true);   
    setViewItem5Button(false);   
   }
   const handleClick5 = ()=>{
    setViewItem6(true);
    setViewItem6Button(false);   
    setViewSubmitButton(true);   
   }

  


 const  validate = ()=> {
    let isValid = true;     

    if (!contactPhone) {
      isValid = false;     
      setErrorMessageContactPhone(<p>{t("pages.contact.text.phoneinvalidmsg")}</p>);
     }

     if (!businessName) {
      isValid = false;     
      setErrorMessageBusinessName(<p>Please select your business name</p>);
     }

     
    if (!contactEmail) {
      isValid = false;
      
      setErrorMessageContactEmail(<p>{t("pages.contact.text.emailinvalidmsg")}</p>);
    }

    if (typeof contactEmail !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(contactEmail)) {
        isValid = false;
        setErrorMessageContactEmail(<p>{t("pages.contact.text.emailpatterninvalidmsg")}</p>);
      }
    }

  };
  
  return (
   <div  className="content-akwaba">
    {isLoggedin && (  <form action="#">
        <div className="case-form" Style=""><p>Fill this form to create an invoice</p></div>   
        <Accordion defaultActiveKey="0">  
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    Your business info  
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body className="content-accordion" >
        <div className="col-md-9 offset-4">
      <div className="form-group">
      <select className ="form-group  option-group" name= "category" id="category" value={category}  onChange={event => setCategory(event.target.value)} >
            <option value="">Select category</option>
            <option value="Ceremonies">Ceremonies</option>
            <option value="Conferences">Conferences</option>         
            <option value="Creative">Creative</option> 
            <option value="CustomEvents">Custom events</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Fairs">Fairs</option>
            <option value="Festivals">Festivals</option>         
            <option value="tvBroadcasts">tvBroadcasts</option> 
            <option value="Music">Music</option>
            <option value="Party">Party</option>    
            <option value="Spectacle">Spectacle</option>
            <option value="newsPapers">newsPapers</option>         
            <option value="Tourism">Tourism</option> 
            <option value="radioBroadcasts">radioBroadcasts</option>
            <option value="Other">Other</option>                          
          </select>
        </div>
        <div className="text-danger">{errorMessageBusinessName}</div>

        <div className="form-group">
        <label>Your email</label>
        <input 
          readonly     
          id="contactEmail"
          name="contactEmail"
          type="text"
          value={contactEmail}
          onChange={event => setContactEmail(event.target.value)}
          placeholder="something@domain.com"
          className= "blured-input"
        />
          <div className="text-danger">{contactEmailErrorMessage}</div>
      </div>

      <div className="form-group">
        <label>Your phone</label>
         <input        
          id="contactPhone"
          name="contactPhone"
          type="text"
          value={contactPhone}
          onChange={event => setContactPhone(event.target.value)} 
          placeholder="123456789"
          className= "blured-input"
        />
          <div className="text-danger">{ contactPhoneErrorMessage}</div>
        </div>
        </div>
        </Card.Body>
    </Accordion.Collapse>
  </Card>

  
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
        Performances(you can add up to 6 performances)    
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body className="content-accordion"> 
      <div className="col-md-9 offset-4">
        <div className="form-group">
        <label>Event Name</label>  
          <input
          id="eventName"
          name="eventName"
          type="text"
          value={eventName}
          onChange={event => setEventName(event.target.value)}          
          placeholder="Event Name"
        />
          <div className="text-danger">{eventNameErrorMessage}</div>
        </div>
        <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 1 Name </label>
        <input
          id="item1Name"
          name="item1Name"
          type="text"
          value={item1Name}
          onChange={event => setItem1Name(event.target.value)}          
          placeholder="Name"
        />
        <div className="text-danger">{item1NameErrorMessage}</div>
        </div>
        </Col>
        </Row>
        <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 1 Venue </label>
        <input
          id="item1Venue"
          name="item1Venue"
          type="text"
          value={item1Venue}
          onChange={event => setItem1Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item1VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>
         <Row>
        <Col md={{ span: 3, offset: 0}}>
         <div className="form-group">
        <label>Budget</label>
        <input
          id="item1Price"
          name="item1Price"
          type="text"
          value={item1Price}
          onChange={event =>setItem1Price(event.target.value)}          
          placeholder="Price"
        />
          <div className="text-danger">{ item1PriceErrorMessage}</div>
        </div>
        </Col>
        <Col md={{ span: 3, offset: 0 }}>
        <div className="form-group">
        <label>Number of invitees</label>
        <input
          id="item1Qty"
          name="item1Qty"
          type="text"
          value={item1Qty}
          onChange={event =>setItem1Qty(event.target.value)}          
          placeholder="Qty"
        />
          <div className="text-danger">{ item1QtyErrorMessage}</div>
        </div>
        </Col>       
        </Row>

        {viewItem2Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick1}
         />
        </div>)}
    
        {viewItem2 && (
        <><Row>
                       <Col md={{ span: 9, offset: 0 }}>    
                        <div className="form-group">
                          <label>Performance 2 Name </label>
                          <input
                            id="item2Name"
                            name="item2Name"
                            type="text"
                            value={item2Name}
                            onChange={event => setItem2Name(event.target.value)}
                            placeholder="Name" />
                          {/* <div className="text-danger">{setErrorMessageItem2Qty}</div> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 2 Venue </label>
        <input
          id="item2Venue"
          name="item2Venue"
          type="text"
          value={item2Venue}
          onChange={event => setItem2Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item2VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>

                    <Row>
                        <Col md={{ span: 3, offset: 0 }}>
                          <div className="form-group">
                            <label>Budget</label>
                            <input
                              id="item2Price"
                              name="item2Price"
                              type="text"
                              value={item2Price}
                              onChange={event => setItem2Price(event.target.value)}
                              placeholder="Price" />
                            {/* <div className="text-danger">{item1PriceErrorMessage}</div> */}
                          </div>
                        </Col>

                        <Col md={{ span: 3, offset: 0 }}>
                          <div className="form-group">
                            <label>Number of invitees</label>
                            <input
                              id="item2Qty"
                              name="item2Qty"
                              type="text"
                              value={item2Qty}
                              onChange={event => setItem2Qty(event.target.value)}
                              placeholder="Qty" />
                            {/* <div className="text-danger">{item2QtyErrorMessage}</div> */}
                          </div>
                        </Col>
                      </Row></>
        )}

      {viewItem3Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick2}
         />
        </div>)}
    

        {viewItem3 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>Performance 3 Name </label>
                        <input
                          id="item3Name"
                          name="item3Name"
                          type="text"
                          value={item3Name}
                          onChange={event => setItem3Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem3Qty}</div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 3 Venue </label>
        <input
          id="item3Venue"
          name="item3Venue"
          type="text"
          value={item3Venue}
          onChange={event => setItem1Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item3VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>
                  
                  <Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Budget</label>
                          <input
                            id="item3Price"
                            name="item3Price"
                            type="text"
                            value={item3Price}
                            onChange={event => setItem3Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item3PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Number of invitees</label>
                          <input
                            id="item3Qty"
                            name="item3Qty"
                            type="text"
                            value={item3Qty}
                            onChange={event => setItem3Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item3QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem4Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick3}
         />
        </div>)}


        {viewItem4 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>Performance 4 Name </label>
                        <input
                          id="item4Name"
                          name="item4Name"
                          type="text"
                          value={item4Name}
                          onChange={event => setItem4Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem4Qty}</div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 4 Venue </label>
        <input
          id="item4Venue"
          name="item4Venue"
          type="text"
          value={item4Venue}
          onChange={event => setItem4Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item4VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>
                  <Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Budget</label>
                          <input
                            id="item4Price"
                            name="item4Price"
                            type="text"
                            value={item4Price}
                            onChange={event => setItem4Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item4PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Number of invitees</label>
                          <input
                            id="item4Qty"
                            name="item4Qty"
                            type="text"
                            value={item4Qty}
                            onChange={event => setItem4Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item4QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem5Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick4}
         />
        </div>)}
        {viewItem5 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>Performance 5 Name </label>
                        <input
                          id="item5Name"
                          name="item5Name"
                          type="text"
                          value={item5Name}
                          onChange={event => setItem5Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem5Qty}</div> */}
                      </div>
                    </Col>

                  </Row>
                  <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 5 Venue </label>
        <input
          id="item5Venue"
          name="item5Venue"
          type="text"
          value={item5Venue}
          onChange={event => setItem1Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item5VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>
                  
                  <Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Budget</label>
                          <input
                            id="item5Price"
                            name="item5Price"
                            type="text"
                            value={item5Price}
                            onChange={event => setItem5Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item5PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Number of invitees</label>
                          <input
                            id="item5Qty"
                            name="item5Qty"
                            type="text"
                            value={item5Qty}
                            onChange={event => setItem5Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item5QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )}
      {viewItem6Button && viewAddMoreItem &&( <div>      
       <input
          type="button"
          value="Add more item or click submit"
          onClick={handleClick5}
         />
        </div>)}
        {viewItem6 && (<><Row>
          <Col md={{ span: 9, offset: 0 }}>    
                      <div className="form-group">
                        <label>Performance 6 Name </label>
                        <input
                          id="item6Name"
                          name="item6Name"
                          type="text"
                          value={item6Name}
                          onChange={event => setItem6Name(event.target.value)}
                          placeholder="Name" />
                        {/* <div className="text-danger">{setErrorMessageItem6Qty}</div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
        <Col md={{ span: 9, offset: 0 }}> 
        <div className="form-group">
        <label>Performance 6 Venue </label>
        <input
          Required
          id="item6Venue"
          name="item6Venue"
          type="text"
          value={item1Venue}
          onChange={event => setItem6Venue(event.target.value)}          
          placeholder="Venue"
        />
        <div className="text-danger">{item6VenueErrorMessage}</div>
        </div>
        </Col>
        </Row>
                  
                  <Row>
                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Budget</label>
                          <input
                            id="item6Price"
                            name="item6Price"
                            type="text"
                            value={item6Price}
                            onChange={event => setItem6Price(event.target.value)}
                            placeholder="Price" />
                          {/* <div className="text-danger">{item6PriceErrorMessage}</div> */}
                        </div>
                      </Col>

                      <Col md={{ span: 3, offset: 0 }}>
                        <div className="form-group">
                          <label>Number of invitees</label>
                          <input
                            id="item6Qty"
                            name="item6Qty"
                            type="text"
                            value={item6Qty}
                            onChange={event => setItem6Qty(event.target.value)}
                            placeholder="Qty" />
                          {/* <div className="text-danger">{item6QtyErrorMessage}</div> */}
                        </div>
                      </Col>
                    </Row></>
        )} 

        <Row>
        {viewSubmitButton &&(
          <Col md={{ span: 6, offset: 0 }}>
        <div>
        <input
         className="btn btn-primary"
          type="submit"
           onClick={(e) =>  {handleSubmit(e)}}
          defaultValue={t("pages.contact.text.submit")}
          />
        </div> 
        </Col>    
             )} 
         </Row>
   
        <Row>         
      {viewCancel && (
      <Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
      <div><button type="submit" onClick={handleCancel} className ="invoice-buttons">Cancel ❌</button></div>
        </Col>)}
      
      {viewSave && (
      <Col md={{ span: 3, offset: 0 }} className="invoice-save-cancel-panel">
      <div><button type="submit" onClick={(e) => {execute(e)}} className ="invoice-buttons">Confirm ✔</button></div>
      </Col>)}      
      </Row>
      </div>
          
        </Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>      
{clickedButtonButNotPosted && (
        <div className="text-danger">
          {clickedNotPostedMessage} <br />                  
        </div>
         )}
      </form>)} 
    </div>
  );
};

export default GeneratePerformance;



