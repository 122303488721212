import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { NavLink, Link, HashRouter, useHistory} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import LocalStorageService from './services/localStorageService';
import DashboardCarousel from './DashboardCarousel';
import FindMoreImage from './imagesfromvuejs/findoutmore.png';
import axios from "axios";
import GetUrl from "./services/urlService";
import Button from 'react-bootstrap/Button';
import config from './config.json';
import HomeDashboardMix from './mixLandingPages/HomeDashboardMix';


 function HomeDashboard() {
  const history = useHistory();    
    LocalStorageService("remove","servicecategory")
    LocalStorageService("set","servicecategory", "home")
    const [post, setPost] = useState([])
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(3) 
    const [search,  setSearch] = useState(false)
    const [isSearchValid,  setIsSearchValid] = useState(true)
    const [siteSearch,  setSiteSearch] = useState('')
    const [siteRoot,  setSiteRoot] = useState('')
    const [env,  setEnv] = useState('')
    const [urlpath,  setUrlpath] = useState('')
    const [siteSearchIndex,  setSiteSearchIndex] = useState('')
    const [eventType,  setEventType] = useState('')
    const [eventId,  setEventId] = useState('')
    
    function getApiPathForSearch () {     
      return GetUrl("searchDashboard");   
    }

    function getApiPathForSearchByName () {     
      return GetUrl("searchDashboardByName");   
    }
 
    useEffect(() => {
      environmentShortDescription();
    }, [])
  
    const  environmentShortDescription = () =>{ 
      if(config.ENVIRONMENT.DEV){
        setEnv("dev")
        }
        if(config.ENVIRONMENT.LIVE){
          setEnv("")
        }

        if(config.ENVIRONMENT.TEST){
          setEnv("test")
        }
    }

    const  handleSearchByCode = (e) =>{
      const url = getApiPathForSearch();

      if(siteSearch=="")
      {     
        setIsSearchValid(false);
        return
      }
      axios.post(url, {         
         siteSearch: siteSearch           
            })
            .then(res => { 
              if (res.status === 200) 
              {
                history.push("details"+`${env}`+res.data[0].TypeOfEvent+'?'+res.data[0].Id);
              }
       
             setSearch(true); 
             setSiteSearch('');
         })
         .catch((e) => console.log(e))    
    } 

    const  handleSearchByName = (e) =>{
      const url = getApiPathForSearchByName();

      if(siteSearch=="")
      {     
        setIsSearchValid(false);
        return
      }
      axios.post(url, {         
         siteSearch: siteSearch           
            })
            .then(res => { 
              if (res.status === 200) 
              {
                history.push("details"+`${env}`+res.data[0].TypeOfEvent+'?'+res.data[0].Id);
              }
       
             setSearch(true); 
             setSiteSearch('');
         })
         .catch((e) => console.log(e))   
    } 
    
  
    return (
      <div>
        <div>  
      <DashboardCarousel/>
       </div>     
     <HashRouter> 
    
      <br/> <br/> 
  <div className="justify-content-md-center "> 
   <div>
   <label for="site-search"  Style ="color:teal" ><h2>Start searching </h2></label>
  </div>
   <div >
   <Col>  
   <input type="search"   value={siteSearch} id="siteSearch"
          onChange={event => setSiteSearch(event.target.value)}
          Style ="border-radius:5px; border: solid teal; width: auto">
   </input>
   <div></div>
     <Button variant="info" Style ="width:80px; paddingRight: 10px; margin: 10px" onClick={(e) =>  {handleSearchByCode (e)}} type ='submit'> Code</Button>
 
     <Button variant="info" Style ="width:100px" onClick={(e) =>  {handleSearchByName(e)}} type ='submit'>Name</Button>
   
   </Col>
   </div>
  </div>
  <br/> <br/>  
<div>
 <div className="row justify-content-md-center">
    <div className="col col-lg-3 m1">
      <NavLink to="/newspapers"><h4><p className="a">News papers</p></h4> 
      <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
      </NavLink> 
       <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m2">        
       <NavLink to="/socialmedias"><h4><p className="a">Social medias</p></h4>
        <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
        </NavLink> 
      <div className="placeholder-dashboard"></div>
    </div>
    <div className="col col-lg-3 m3">            
        <NavLink to="/onlinearticles"><h4><p className="a">Online articles</p></h4>
         <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
         </NavLink> 
         <div className="placeholder-dashboard"></div>
    </div>
  </div>
 <div className="row justify-content-md-center">
    <div className="col col-lg-3 m4">            
        <NavLink to="/tvbroadcasts"><h4><p className="a">TV broadcasts</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/> 
        </NavLink> 
         <div className="placeholder-dashboard"></div>
    </div>
     <div className="col col-lg-3 m5"> 
        <NavLink to="/radiobroadcasts"><h4><p className="a">Radio broadcasts</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
         <div className="placeholder-dashboard"></div>     
    </div>
    <div className="col col-lg-3 m6"> 
        <NavLink to="/miscellaneous"><h4><p className="a">Miscellaneous</p></h4> <img src={FindMoreImage} width="130px" height="30px"  className="imgb"/>
         </NavLink> 
         <div className="placeholder-dashboard"></div>     
    </div>
    </div>   
</div>

    <br/> <br/>  
      <HomeDashboardMix/>
      <br/><br/>
 </HashRouter> 
</div>

    )
     
    }

export default withTranslation() (HomeDashboard);


 
